import { useEffect } from 'react';
import { checkWindow, PAGES } from '@vfit/shared/data-access';
import { dehydrate } from 'react-query';
import { usePageProvider } from '@vfit/consumer/providers';
import { Layout } from '@vfit/consumer/components';
import {
  CMS_CONFIG,
  getStaticPathPages,
  IPageProps,
  IStaticPathPagesPaths,
} from '@vfit/consumer/data-access';
// eslint-disable-next-line import/no-relative-packages
import prefetchAllQueries from '../../../libs/consumer/data-access/src/lib/cms/prefetchFS';

export default function PageBuilder({ page }: IPageProps) {
  const { setPage } = usePageProvider();
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const isApp = urlParams.get('app');

  useEffect(() => {
    if (page) {
      setPage(page);
    }
  }, []);

  return <Layout page={page} isApp={!!isApp} />;
}

export async function getStaticPaths() {
  const allPages = await getStaticPathPages('grandiaziende-homepage');
  return {
    paths: allPages,
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  const contextPath = params?.path?.join('/') || '';
  const allPages = await getStaticPathPages('grandiaziende-homepage');
  const pageObject: IStaticPathPagesPaths | null =
    allPages.find((page) => page.params?.path?.join('/') === contextPath) || null;
  const pageNavigation = await prefetchAllQueries(CMS_CONFIG[PAGES.GRANDIAZIENDE_HOME_PAGE]);
  return {
    props: {
      page: pageObject,
      dehydratedState: dehydrate(pageNavigation),
    },
  };
}
